'use strict';

window.jQuery = window.$ = require('jquery');
require('lazyloadxt/dist/jquery.lazyloadxt.extra.min');
require('lazyloadxt/dist/jquery.lazyloadxt.bg.min');

var debounce = require('lodash/debounce');
var endpointMini = $('.suggestions-wrapper-mini').data('url');
var minChars = 3;
var UP_KEY = 38;
var DOWN_KEY = 40;
var DIRECTION_DOWN = 1;
var DIRECTION_UP = -1;

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
    loadTrustPilotWidgets('updateDom');
}
function loadTrustPilotWidgets(origin){
    $(".trustpilot-widget-blank").each(function(){
        var target = $(this).parents("div.trustpilot-widget")[0];
        window.Trustpilot.loadFromElement(target);
    });    
}

function updateFilterBtn() {
    if($('.collapsible-title.refined').length > 2) {
        $('.refinement-bar .btn-filter').addClass('refined');
    } else {
        $('.refinement-bar .btn-filter').removeClass('refined');
    }
}
/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results) {
    const collapsible = require('../components/collapsible');
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        var activeDiv = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));
        activeDiv.addClass('active');
        activeDiv.find('button.title').attr('aria-expanded', 'true');
    });
    updateDom($results, '.refinements');
    collapsible.collapsibleInit();
}
/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    var $results = $(response);
    var specialHandlers = {
        '.refinements': handleRefinements
    };
    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.page-title',
        '.product-grid',
        '.show-more',
        '.product-grid-top-bar',
        '#sort-tab-content',
		'.selected-filters',
    ].forEach(function (selector) {
        updateDom($results, selector);
    });
    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });

    updateFilterBtn();
}

/**
 * Update filter accordion with localStorage data
 */
function updateFilterAccordion() {
    $('.collapsible').each(function() {   
        var $elementID = $(this).children('div.collapse').attr('id');  
        if ($elementID !== 'refinement-category') {
            if (localStorage.getItem($elementID) && localStorage.getItem($elementID) === 'false') {
                $(this).children('div.collapse').removeClass('show');
                $(this).children('div.card-header').find('.collapsible-title').attr('aria-expanded', 'false');
                $(this).removeClass('open');    
            } else if (localStorage.getItem($elementID) === 'true') {
                $(this).children('div.collapse').addClass('show');
                $(this).children('div.card-header').find('.collapsible-title').attr('aria-expanded', 'true');
                $(this).addClass('open');   
            }
        }
    });
}

/**
 * Update the product second image URL
 */
function updateImageURL() {
	var imgDefer = document.getElementsByClassName('card-img-lifestyle-second');
    for (var i = 0; i < imgDefer.length; i++) {
        const img = imgDefer[i];
        const imgDataSrc = img.getAttribute('data-src');
        if (imgDataSrc) {
            img.setAttribute('src', imgDataSrc);
        }
    }
}

function updateProductTile() {
	const $swatchSelected = $('.swatch.selected');
	if ($swatchSelected.length > 0) {
		for (let i = 0; i < $swatchSelected.length; i++) {
			const swatch = $swatchSelected[i];
			const $tile = $(swatch)
				.parents()
				.eq(5)
				.find('.product-tile-card');
			const swatchLink = $(swatch).parent();
			const imgData = swatchLink.data('image');
			const imgDataNext = swatchLink.data('imagenext');
			const swatchPdpUrl = swatchLink.data('pdpurl');
			const imgDataURL = imgData && imgData.url ? imgData.url : '';
			const imgDataNextURL = imgDataNext && imgDataNext.url ? imgDataNext.url : imgDataURL;

			$tile.find('.plp-image-link').attr('href', swatchPdpUrl);
			$tile.find('.card-img-lifestyle-main').attr('src', imgDataURL);
			$tile.find('.card-img-lifestyle-main').attr('data-src', imgDataURL);
			$tile.find('.card-img-lifestyle-second').attr('src', imgDataNextURL);
			$tile.find('.card-img-lifestyle-second').attr('data-src', imgDataNextURL);
			if (swatchLink.hasClass('d-none')) {
				const availableSwatches = swatchLink.parent().find('.swatch-link:not(.d-none)');
				$(availableSwatches[availableSwatches.length - 1]).addClass('d-none');
				swatchLink.removeClass('d-none');
			}

			const qwButtons = $(swatch)
				.parents()
				.eq(6)
				.find('.qw-buttons');
			if (qwButtons.length > 0) {
				const pid = $(swatch)
					.parent()
					.data('product-id');
				qwButtons.attr('data-pid', pid);
				const isStoreSelected = qwButtons.data('store');
				const productAvailability = $(swatch)
					.parent()
					.data('product-availablity');
				const showNotifyMe = $(this).data('show-notify-me');
				if (isStoreSelected) {
					if (productAvailability) {
						if (qwButtons.find('.plp-out-of-stock') && qwButtons.find('.plp-out-of-stock').length > 0) {
							qwButtons.find('.plp-out-of-stock').addClass('d-none');
						} else {
							qwButtons.find('.notify-me-button').addClass('d-none');
						}
						qwButtons.find('.quick-view-button ').removeClass('d-none');
					} else {
						if (qwButtons.find('.plp-out-of-stock') && qwButtons.find('.plp-out-of-stock').length > 0) {
							qwButtons.find('.plp-out-of-stock').removeClass('d-none');
						} else if (showNotifyMe) {
							if (qwButtons.find('.notify-me-button') && qwButtons.find('.notify-me-button').length > 0) {
								qwButtons.find('.notify-me-button').removeClass('d-none');
							} else {
								qwButtons.find('.plp-out-of-stock').text('Notify Me');
								qwButtons
									.find('.plp-out-of-stock')
									.removeClass('plp-out-of-stock')
									.addClass('notify-me-button');
								qwButtons.find('.notify-me-button').removeClass('d-none');
							}
						}
						qwButtons.find('.quick-view-button ').addClass('d-none');
					}
				} else {
					qwButtons.find('.quick-view-button ').removeClass('d-none');
				}
			}
			const pdpURL = $(swatch)
				.parent()
				.data('pdpurl');
			const variantURL = `window.location=${pdpURL}`;
			const parentElement = $(swatch)
				.parents()
				.eq(3);
			parentElement.find('.product-tile-name-wrap').attr('onclick', variantURL);
			parentElement
				.find('.product-tile-name-wrap')
				.find('.card-title.product-tile-name')
				.attr('href', pdpURL);
			parentElement.find('.card-text.product-tile-subtitle').attr('href', pdpURL);
			parentElement.find('.product-tile-price a').attr('href', pdpURL);
		}
	}
}

/**
 * This function retrieves another page of content to display in the content search grid
 * @param {JQuery} $element - the jquery element that has the click event attached
 * @param {JQuery} $target - the jquery element that will receive the response
 * @return {undefined}
 */
function getContent($element, $target) {
    var showMoreUrl = $element.data('url');
    $.spinner().start();
    $.ajax({
        url: showMoreUrl,
        method: 'GET',
        success: function (response) {
            $target.append(response);
            updateImageURL();
            $(window).lazyLoadXT();
            $.spinner().stop();
            loadTrustPilotWidgets('getContent');
        },
        error: function () {
            $.spinner().stop();
        }
    });
}
/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateSortOptions(response) {
    var $tempDom = $('<div>').append($(response));
    var sortOptions = $tempDom.find('.grid-footer').data('sort-options').options;
    sortOptions.forEach(function (option) {
        $('option.' + option.id).val(option.url);
    });
}
function getUrlParameter(URL, sParam) {
    var sURLVariables = URL.split('&'),
        sParameterName,
        i;
    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
}
function redrawContentGridWithFilters(url, p_start=false, p_sz=false){
    return new Promise(function(s,f){
        var newURL = updateURLParameter(url, 'start', '0');
        var scrollPos = getSessionStorageValue('scrollPos');
        if(p_start && p_sz){
            newURL = updateURLParameter(newURL, 'sz', p_start);
        }
        newURL = newURL.replace('Search-UpdateGrid', 'Search-RestoreAjax');
        $.spinner().start();
        $.ajax({
            url: newURL,
            data: {
                page: $('.grid-footer').data('page-number')
            },
            method: 'GET',
            success: function (response) {
                parseResults(response);
                $(window).scrollTop(scrollPos);
                sessionStorage.removeItem(window.location.pathname);              
                $.spinner().stop();
                s(response);
                updateImageURL();
                $(window).lazyLoadXT();
                loadTrustPilotWidgets('redrawContentGridWithFilters');
            },
            error: function (err) {
                $.spinner().stop();
                removeSessionStorageValue('filterURL');
                f(err);
            }
        });
    });
}
function updateViewMoreButtonURL(start, sz){
    var c = $('.show-more button').data('url');
    if(c){
        var result = updateURLParameter(c, 'start', start);
        if(sz > 0)
            result = updateURLParameter(result, 'sz', sz);
        $('.show-more button').data('url',result);    
    }
}
function updateURLParameter(url, param, paramVal){
    var newAdditionalURL = "";
    var tempArray = url.split("?");
    var baseURL = tempArray[0];
    var additionalURL = tempArray[1];
    var temp = "";
    if (additionalURL) {
        tempArray = additionalURL.split("&");
        for (var i=0; i<tempArray.length; i++){
            if(tempArray[i].split('=')[0] != param){
                newAdditionalURL += temp + tempArray[i];
                temp = "&";
            }
        }
    }
    var rows_txt = temp + "" + param + "=" + paramVal;
    return baseURL + "?" + newAdditionalURL + rows_txt;
}
function updateSessionStorageValue(key, value){
    var values = {};
    if(sessionStorage.getItem(window.location.pathname)){
        values = JSON.parse(sessionStorage.getItem(window.location.pathname));        
    }
    values[key] = value;
    sessionStorage.setItem(window.location.pathname, JSON.stringify(values));
}
function getSessionStorageValue(key){
    if(sessionStorage.getItem(window.location.pathname)){
        var values = JSON.parse(sessionStorage.getItem(window.location.pathname));
        for (var i = 0; i < Object.keys(values).length; i++){
            if(Object.keys(values)[i] == key) return values[key];        
        }  
    }
    return false;
}
function removeSessionStorageValue(key){
    if(sessionStorage.getItem(window.location.pathname)){
        var values = JSON.parse(sessionStorage.getItem(window.location.pathname));
        if(values[key]){
            delete values[key];
            sessionStorage.setItem(window.location.pathname, JSON.stringify(values));
        } 
    }
}
function getUri(c) {
    var d;
    if ("string" != typeof c) return null;
    d = document.createElement("a"), d.href = c
    return {
        protocol: d.protocol,
        host: d.host,
        hostname: d.hostname,
        port: d.port,
        path: d.pathname,
        query: d.search,
        queryParams: d.search.length > 1 ? getQueryStringParams(d.search.substr(1)) : {},
        hash: d.hash,
        url: d.protocol + "//" + d.host + d.pathname,
        urlWithQuery: d.protocol + "//" + d.host + d.port + d.pathname + d.search
    }
}
function getQueryStringParams(a){
    if (!a || 0 === a.length) return {};
    var b = {};
    return unescape(a).replace(new RegExp("([^?=&]+)(=([^&]*))?", "g"), function(a, c, d, e) {
        b[c] = e
    }), b
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestionsMini(scope) {
    if ($(scope).val().length >= minChars) {
        $.ajax({
            context: scope,
            url: endpointMini + encodeURIComponent($(scope).val()),
            method: 'GET',
            success: processResponse,
            error: function () {
                $.spinner().stop();
            }
        });
    } else {
        toggleSuggestionsIconMini('search');
        $(scope).siblings('.reset-button').removeClass('d-sm-block');
        getSuggestionsWrapperMini(scope).empty();
    }
}

/**
 * Calculate suggestions block height
 *
 * @param {Object} $element - Search suggestions block element
 */
 function getSuggestionsHeight($element) {
    const headerMobileBreakpoint = 1279; // this number is used as a breakpoint in scss and is stored in $header-mobile-breakpoint variable
    const width = window.innerWidth || document.documentElement.clientWidth;
    const topBannerHeight = $('.header-banner').outerHeight() || 0;

    if (width < headerMobileBreakpoint) {
        const heightShift = topBannerHeight + $('.header-mobile-container').outerHeight();
        $element.css('max-height', `calc(100vh - ${heightShift}px)`); //without a height limit the suggestion block can't be scrolled on mobile
    }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    var $suggestionsWrapper = getSuggestionsWrapperMini(this).empty();

    $.spinner().stop();
    $('.modal-background').hide();
    $('input.search-field-action').removeAttr('disabled').focus();
    $('.menu-group-bottom').find('.nav-link.showing').removeClass('showing');
    $('.menu-group-bottom').find('.dropdown-menu-container.show').removeClass('show');
    if (!(typeof (response) === 'object')) {
        $suggestionsWrapper.append(response);
        getSuggestionsHeight($suggestionsWrapper.find('.suggestions'));
        $suggestionsWrapper.show();

        if ($suggestionsWrapper.find('.suggestions-noresults').length !== 0) {
            $('#suggestions-wrapper-mini').find('.suggestions').css('overflow-y', 'hidden');
            $('#suggestions-wrapper-mini').find('.suggestions').css('padding-bottom', '10px');
        }

        $(this).siblings('.reset-button-mini').addClass('d-sm-block');
        positionSuggestions(this);

        if (isMobileSearch(this)) {
            toggleSuggestionsIconMini('close');
            applyModals(this);
        }
        updateImageURL();
        $(window).lazyLoadXT();
        loadTrustPilotWidgets('processResponse');

        // Trigger screen reader by setting aria-describedby with the new suggestion message.
        var suggestionsList = $('.suggestions .item');
        if ($(suggestionsList).length) {
            $('input.search-field-action').attr('aria-describedby', 'search-result-count');
            setTimeout(function(){
                var searchQuery = $('input.search-field-action').val();
                window.dataLayer.push({
                    event : "interaction",
                    component : "searchBar",
                    action : "Successful Search",
                    searchTerm: searchQuery
                });
            }, 1000);
        } else {
            $('input.search-field-action').removeAttr('aria-describedby');
        }
    } else {
        $suggestionsWrapper.hide();
    }
}
/**
 * Apply modal classes needed for mobile suggestions
 *
 * @param {Object} scope - Search input field DOM element
 */
function applyModals(scope) {
    if (isMobileSearch(scope)) {
        $('body').addClass('modal-open');
        $('header').siblings().attr('aria-hidden', 'true');
        getSuggestionsWrapperMini(scope).find('.suggestions').addClass('modal');
    }
}
/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
    return !!$(scope).closest('.search-mobile').length;
}
/**
 * Positions Suggestions panel on page
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function positionSuggestions(scope) {
    var outerHeight;
    var $scope;
    var $suggestions;
    var top;

    if (isMobileSearch(scope)) {
        $scope = $(scope);
        top = $scope.offset().top;
        outerHeight = $scope.outerHeight();
        $suggestions = getSuggestionsWrapperMini(scope).find('.suggestions');
        $suggestions.css('top', top + outerHeight);

        handleMoreContentBelowIcon(scope);

        // Unfortunately, we have to bind this dynamically, as the live scroll event was not
        // properly detecting dynamic suggestions element's scroll event
        $suggestions.scroll(function () {
            handleMoreContentBelowIcon(this);
        });
    }
}
/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapperMini(scope) {
    return $('#suggestions-wrapper-mini');
}

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
function toggleSuggestionsIconMini(action) {
    var mobileSearchIcon = '.search-mobile button.';
    var iconSearch = 'fa-search';
    var iconSearchClose = 'fa-close';

    if (action === 'close') {
        $(mobileSearchIcon + iconSearch).removeClass(iconSearch).addClass(iconSearchClose).attr('type', 'button');
    } else {
        $(mobileSearchIcon + iconSearchClose).removeClass(iconSearchClose).addClass(iconSearch).attr('type', 'submit');
    }
}

module.exports = {
    sort: function () {
        // Handle sort order menu selection

        $('body').on( 'click', '.opt-sort-by', function(){
            setTimeout(function(){
            $('#sort-tab').trigger('click');
        }, 100);
        });
        
        $('body').on( 'click', '.opt-filter', function(){
            setTimeout(function(){
            $('#filter-tab').trigger('click');
        }, 100);
        });
        
        $('.search-results').on('change', '[name=sort-order]', function (e) {
            e.preventDefault();
            $.spinner().start();
            var sortUrl = this.value;
            sortUrl = sortUrl.replace('Search-UpdateGrid', 'Search-RestoreAjax');
            $(this).trigger('search:sort', sortUrl);
            var postURI = getUri(sortUrl);
            window.history.pushState({}, 'Amart Furniture', postURI.query);
            $.ajax({
                url: sortUrl,
                method: 'GET',
                success: function (response) {
                    parseResults(response);
                    updateImageURL();
					updateProductTile();
                    $(window).lazyLoadXT();
                    updateFilterAccordion();
                    $.spinner().stop();
                    loadTrustPilotWidgets('sort');
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
        $(window).on("popstate", function(e) {
            if (e.originalEvent.state !== null) {
            location.reload()
            }
        });
    },
    showMore: function () {
        // Show more products
        $('.container').on('click', '.show-more button', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            var showMoreUrl = $(this).data('url');
            $.spinner().start();
            $(this).trigger('search:showMore', e);
            $.ajax({
                url: showMoreUrl,
                method: 'GET',
                success: function (response) {
                    $('.grid-footer').replaceWith(response);
                    updateSortOptions(response);
                    updateImageURL();
					updateProductTile();
                    $(window).lazyLoadXT();
                    $.spinner().stop();
                    loadTrustPilotWidgets('showMore');
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },
    applyFilter: function () {
        // Handle refinement value selection and reset click
        $('.container').on(
            'click',
            '.refinements ul:not(.categories) li button, .refinement-bar button.reset, .filter-value button, .swatch-filter button, .filter-applied-header .filter-clear-all, .selected-filters-children .applied-filter',
            function (e) {
                e.preventDefault();
                e.stopPropagation();
                if ($('.search-results.no-results').length > 0 ) {
             	   var categoryURL = $('.filter-card-container.refinements').data('category-url');
              	   window.location.href = categoryURL;
                }
                $.spinner().start();
                $(this).trigger('search:filter', e);
                var postURI = getUri($(this).data('href'));
                window.history.pushState({}, 'Amart Furniture', postURI.query); 

                $.ajax({
                    url: $(this).data('href'),
                    data: {
                        page: $('.grid-footer').data('page-number')
                    },
                    method: 'GET',
                    success: function (response) {
                        parseResults(response);
                        updateImageURL();
                        updateProductTile();
                        updateFilterAccordion();
                        $(window).lazyLoadXT();
                        $.spinner().stop();
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            });
            $(window).on("popstate", function(e) {
                if (e.originalEvent.state !== null) {
                location.reload()
                }
            });
            
            updateFilterBtn();
    },
    showContentTab: function () {
        // Display content results from the search
        $('.container').on('click', '.content-search', function () {
            if ($('#content-search-results').html() === '') {
                getContent($(this), $('#content-search-results'));
            }
        });
        // Display the next page of content results from the search
        $('.container').on('click', '.show-more-content button', function () {
            getContent($(this), $('#content-search-results'));
            $('.show-more-content').remove();
        });
    },

    addMiniSearchEventListener: function () {
            /**
             * Use debounce to avoid making an Ajax call on every single key press by waiting a few
             * hundred milliseconds before making the request. Without debounce, the user sees the
             * browser blink with every key press.
             */
            var debounceSuggestions = debounce(getSuggestionsMini, 300);
            $('body').on('click', 'input.search-field-action', function(e){
                if ($(this).val()) {
                    debounceSuggestions(this, e);
                    $('.menu-group-bottom').find('.nav-link.showing').removeClass('showing');
                    $('.menu-group-bottom').find('.dropdown-menu-container.show').removeClass('show');
                }
            });
            $('input.search-field-action').on('keyup', function (e) {
                // Capture Down/Up Arrow Key Events
                switch (e.which) {
                    case DOWN_KEY:
                        handleArrow(DIRECTION_DOWN);
                        e.preventDefault(); // prevent moving the cursor
                        break;
                    case UP_KEY:
                        handleArrow(DIRECTION_UP);
                        e.preventDefault(); // prevent moving the cursor
                        break;
                    default:
                        debounceSuggestions(this, e);
                }
            });
    },
    storeScrollPosition: function(){      
        $(document).ready(function(){
            $(".container").on("click", "div.card.product-tile-card a", function(e){
                e.preventDefault();
                e.stopPropagation();
                var viewMoreButton = $('div.show-more .btn-outline-primary');
                if(viewMoreButton.length){
                    var sz = getUrlParameter(viewMoreButton.data('url'),'sz');
                    var start = getUrlParameter(viewMoreButton.data('url'),'start');
                    var url = viewMoreButton.data('url');
                    updateSessionStorageValue('filterURL', viewMoreButton.data('url'));  
                    updateSessionStorageValue('sz', sz);            
                    updateSessionStorageValue('start', start);                     
                }
                updateSessionStorageValue('scrollPos', $(window).scrollTop());            
                window.location.href = $(this)[0].href;
            });     
        });
        var init = function () {
            // Prevent automatic page location restoration
            if ('scrollRestoration' in history) history.scrollRestoration = 'manual';
            var scrollPos = getSessionStorageValue('scrollPos');
            if(scrollPos){
                var sz = getSessionStorageValue('sz');
                var start = getSessionStorageValue('start');
                var filterURL = getSessionStorageValue('filterURL');
                if(filterURL){ // filter with show more action
                    redrawContentGridWithFilters(filterURL, start, sz).then(function(r){
                        $(window).scrollTop(scrollPos);
                        sessionStorage.removeItem(window.location.pathname);                    
                    }).catch(function(err){
                        console.log(err);
                    })                    
                }else{
                    $(window).scrollTop(scrollPos);
                    sessionStorage.removeItem(window.location.pathname);           
                }
            }
        };
        window.onload = init;          
    },
    suggestionsControlsEventListeners: function () {
        $(document).on('click', '#hide-search-suggestions', function (e) {
            e.preventDefault();
            $('.suggestions').hide();
            $('.header').removeClass('header-has-search');
        });

        $(document).on('click', '#clear-header-search', function (e) {
            e.preventDefault();
            $('.search-bar-field').val('');
            $('.suggestions').hide();
            $('.header').removeClass('header-has-search');
        });

        $(document).on('click', '.suggestions-result a', function () {
            if ($(this).parent().data('gtm')) {
                window.dataLayer.push({
                    event : "interaction",
                    component : "searchBar",
                    action : "search selected",
                    element : $(this).parent().data('gtm')
                });
            }
        });

        $('body').on('click', '.suggestions-result [data-gtm-product-link]', function (e) {
            window.dataLayer.push({
                event : "interaction",
                component : "searchBar",
                action : "search selected",
                element : 'PRODUCT'
            });
        });

        $(window).on('resize', function () {
            const $suggestions = $('#suggestions-wrapper-mini .suggestions');

            if($suggestions.length) {
                getSuggestionsHeight($suggestions);
            }
        });

        const $suggestionsWrapperMini = $('#suggestions-wrapper-mini');
        const updateMaincontentMargin = function () {
            $('#maincontent').css('margin-top', $('header').height());
        }
    },
    clearTabClass: function() {
        $(document).on('click', '.mobile-filter-tabs .btn', function() {
                $(this).siblings('.btn').removeClass('active').attr('aria-selected', 'false');
        });
    },
    lazyLoadOnHover: function() {
        $('.product-overlay-wrapper').on('mouseover', function() {
            if(!$(this).parent().parent().parent().hasClass('swiper-slide')) {
                updateImageURL();
            }
        });
    },
	ProductTileUpdate: function() {
        $(document).ready(function() {
            updateProductTile();
        });
    }
};
